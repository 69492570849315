<template>
  <div class="store-lists">
    <div class="tw-container">
      <!-- 試用30才會出現的加入客服群組 -->
      <div v-if="show30DaysFreeText">
        <button
          class="tw-btn tw-btn-gold tw-text-size18 mb-3"
          @click="open30DaysFreeText = !open30DaysFreeText"
        >
          點我看試用期開通流程
        </button>
        <ul class="fw-bolder" v-if="open30DaysFreeText">
          <li class="mb-2">
            <p>1. 在您的帳號中建立您第一個賣場後</p>
          </li>
          <li class="mb-2">
            <p>2. 加入易團購 Line 客服官方帳號</p>
            <img
              src="@/assets/line-official/M_gainfriends_qr.png"
              alt="Line客服QRcode"
            />
          </li>
          <li class="mb-2">
            <p class="mb-2">
              3. 下圖為「易團購 Line 客服官方帳號」，接著點擊「加入好友」
            </p>
            <img
              src="@/assets/line-official/line-official.jpg"
              alt="Line客服QRcode"
              style="width: 50%"
            />
          </li>
          <li class="mb-2">
            <p class="mb-2">
              4. 接著前往「易團購 Line 客服官方帳號」輸入您在系統所申辦的帳號
            </p>
            <img
              src="@/assets/line-official/line-customer-service1.png"
              alt="Line客服QRcode"
              style="width: 50%"
            />
          </li>
          <li class="mb-2">
            <p class="mb-2">
              5. 發送完成後，等待客服人員核對無誤之後即可開通您的試用期
            </p>
            <img
              src="@/assets/line-official/line-customer-service2.png"
              alt="Line客服QRcode"
              style="width: 50%"
            />
          </li>
        </ul>
      </div>
      <!-- 建立賣場 -->
      <div class="tw-container border" v-if="!canNotCreateStore">
        <!-- 上方 -->
        <div class="row mb-2">
          <div class="col-md-4 col-12 p-0">
            <p class="fw-bold tw-text-size18 d-inline me-3">
              建立賣場
              <a href="https://youtu.be/qTPwuMNq1Og?si=aTj_n0ofFjYllMKo" target="_blank">
                <img
                  class="info-icon"
                  src="@/assets/icon/interrogation.png"
                />
              </a>
            </p>
            <button class="tw-btn tw-btn-success" @click="createStore">
              建立
            </button>
          </div>
          <span
            class="
              fw-bolder
              text-danger
              alert alert-info
              text-center
              p-2
              col-md-8 col-12
              p-0
              m-0
            "
            v-if="show30DaysFreeText"
          >
            建立完賣場後，<a
              href=""
              @click.prevent="showModal('showCustomerService')"
              >聯絡客服</a
            >即可幫您開通 30 天試用期呦 ^^
          </span>
        </div>
        <!-- 下方 -->
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            id="StoreName"
            placeholder="賣場名稱(30字)"
            maxlength="30"
            v-model="storeName"
          />
          <label for="StoreName">賣場名稱(30字)</label>
        </div>
      </div>
      <!-- 賣場列表 -->
      <div class="tw-container border">
        <!-- 上方 -->
        <div class="mb-3">
          <p class="fw-bold tw-text-size18 d-inline me-3">查看賣場列表</p>
        </div>
        <!-- 下方 -->
        <div class="all-store-lists">
          <!-- 我的賣場 -->
          <div class="mb-3 table-responsive">
            <p class="title fw-bold tw-border-start mb-2">我的賣場</p>
            <table
              class="table table-hover"
              v-if="ownerStores.data.length > 0"
            >
              <thead>
                <tr>
                  <th style="min-width: 120px">賣場名稱</th>
                  <!-- <th style="min-width: 120px">管理狀態</th> -->
                  <th style="min-width: 300px" class="text-start">快速設定</th>
                  <th style="min-width: 120px">操作</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="data in ownerStores.data" :key="data.storeId">
                  <tr :class="{'shield': !((data.authority === 'OWNER' && data.is_inSubscription) || data.authority === 'CUSTOMER')}" v-if="!data.store.deleted">
                    <th scope="row">
                      <p class="alert-success p-1 mb-2" v-if="!data.participantPermission && data.authority === 'OWNER'">賣家</p>
                      <p class="alert-danger p-1 mb-2" v-else>小幫手</p>
                      {{ data.store.name }}
                    </th>
                    <!-- <td>
                      <div class="mb-1">
                        <p class="text-success" v-if="!data.store.deleted">
                          管理中
                        </p>
                        <p class="text-danger" v-else>取消管理</p>
                      </div>
                      <div>
                        <button
                          class="tw-btn tw-btn-danger"
                          v-if="!data.store.deleted"
                          @click="showModal('setDeletedTrue', data)"
                        >
                          取消管理
                        </button>
                        <button
                          class="tw-btn tw-btn-success"
                          v-else
                          @click="showModal('setDeletedFalse', data)"
                        >
                          繼續管理
                        </button>
                      </div>
                    </td> -->
                    <td class="text-start">
                      <!-- 開放個人賣場 -->
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          :id="`OpenShop${data.storeId}`"
                          v-model="data.store.opened"
                          @click="setStoreAttribute('setOpened', data)"
                        />
                        <label
                          class="form-check-label"
                          :for="`OpenShop${data.storeId}`"
                          >開放個人賣場</label
                        >
                      </div>
                      <!-- 顧客是否自行結單 -->
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          :id="`CheckOutByCustomer${data.storeId}`"
                          v-model="data.store.canCheckOut"
                          @click="setStoreAttribute('setCanCheckOut', data)"
                        />
                        <label
                          class="form-check-label"
                          :for="`CheckOutByCustomer${data.storeId}`"
                          >顧客是否自行結單</label
                        >
                      </div>
                      <!-- 顧客於個人賣場下單前是否需填寫收件資訊 -->
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          :id="`NeedReceiptInfo${data.storeId}`"
                          v-model="data.store.receivingInfoNeeded"
                          @click="
                            setStoreAttribute('setReceivingInfoNeeded', data)
                          "
                        />
                        <label
                          class="form-check-label"
                          :for="`NeedReceiptInfo${data.storeId}`"
                          >顧客於個人賣場下單前是否需填寫收件資訊</label
                        >
                      </div>
                      <!-- 顧客審查機制 -->
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          :id="`VerifyUser${data.storeId}`"
                          v-model="data.store.verifyNeeded"
                          @click="setStoreAttribute('setVerifyNeeded', data)"
                        />
                        <label
                          class="form-check-label"
                          :for="`VerifyUser${data.storeId}`"
                          >顧客審查機制</label
                        >
                      </div>
                    </td>
                    <td>
                      <button
                        class="tw-btn tw-btn-secondary me-2 mb-2"
                        @click="showModal('editStoreName', data)"
                      >
                        編輯賣場名稱
                      </button>
                      <button
                        class="tw-btn tw-btn-danger"
                        @click="showModal('setDeletedTrue', data)"
                      >
                        刪除賣場
                      </button>
                      <!-- <button
                        class="tw-btn tw-btn-danger pointer"
                        @click="showModal('delStore', data)"
                        v-if="data.store.deleted"
                      >
                        刪除賣場
                      </button>
                      <button
                        v-else
                        class="tw-btn tw-btn-danger not-allowed"
                        @click="showModal('delStore', data)"
                        title="要先取消管理才能刪除賣場!"
                        disabled
                      >
                        刪除賣場
                      </button> -->
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <p class="fw-bolder text-danger" v-else>目前尚未有任何自己賣場!</p>
          </div>
          <!-- 小幫手 -->
          <div v-if="userInfo && userInfo.participantPermissions.length > 0">
            <div>
              <span class="tw-border-start fw-bolder me-2" @click="createHelper">小幫手</span>
            </div>
            <div>
              <div
                class="table-responsive"
              >
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th style="width: 30px">
                        欄位 ID
                      </th>
                      <th style="width: 130px">小幫手</th>
                      <th style="min-width: 150px">權限</th>
                      <th style="width: 50px">操作</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="bg-light"
                      v-for="(data, index) in userInfo.participantPermissions"
                      :key="data.id"
                    >
                      <th>{{ data.id }}</th>
                      <td class="text-start">
                        <p class="text-center mb-2 pointer" @click="showModal('checkPermissions', data)">
                          <span class="alert-success" v-if="data.inPeriod">已生效</span>
                          <span class="alert-danger" v-else>已過期或尚未生效</span>
                        </p>
                        <div class="form-floating mb-3">
                          <input
                            onwheel="this.blur()"
                            type="number"
                            class="form-control"
                            :id="`ParticipantId${data.id}`"
                            placeholder="輸入顧客ID"
                            v-model="data.participantId"
                          />
                          <label :for="`ParticipantId${data.id}`">輸入顧客ID</label>
                        </div>
                        <p v-if="data.participant">
                          <p>姓名: <span class="text-success">{{ data.participant.user.name }}</span></p>
                          <p>賣場: <span class="text-success">{{ data.participant.store.name }}</span></p>
                        </p>
                      </td>
                      <td>
                        <div class="text-start row">
                          <div class="col-md-6 col-12">
                            <!-- 允許查看成本 -->
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                :id="`AccessCost${data.id}`"
                                v-model="data.accessCost"
                              />
                              <label
                                class="form-check-label"
                                :for="`AccessCost${data.id}`"
                                >允許查看成本</label
                              >
                            </div>
                            <!-- 允許查看業績 -->
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                :id="`AccessStoreReport${data.id}`"
                                v-model="data.accessStoreReport"
                              />
                              <label
                                class="form-check-label"
                                :for="`AccessStoreReport${data.id}`"
                                >允許查看業績</label
                              >
                            </div>
                            <!-- 允許查看供應商詳細資訊 -->
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                :id="`AccessSupplier${data.id}`"
                                v-model="data.accessSupplier"
                              />
                              <label
                                class="form-check-label"
                                :for="`AccessSupplier${data.id}`"
                                >允許查看供應商詳細資訊</label
                              >
                            </div>
                            <!-- 允許刪除訂單-->
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                :id="`DeleteMerchOrder${data.id}`"
                                v-model="data.deleteMerchOrder"
                              />
                              <label
                                class="form-check-label"
                                :for="`DeleteMerchOrder${data.id}`"
                                >允許刪除訂單</label
                              >
                            </div>
                            <!-- 允許刪除/復原商品 -->
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                :id="`DeleteMerchandise${data.id}`"
                                v-model="data.deleteMerchandise"
                              />
                              <label
                                class="form-check-label"
                                :for="`DeleteMerchandise${data.id}`"
                                >允許刪除/復原商品</label
                              >
                            </div>
                          </div>
                          <div class="col-md-6 col-12">
                            <!-- 允許刪除/復原顧客 -->
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                :id="`DeleteParticipant${data.id}`"
                                v-model="data.deleteParticipant"
                              />
                              <label
                                class="form-check-label"
                                :for="`DeleteParticipant${data.id}`"
                                >允許刪除/復原顧客</label
                              >
                            </div>
                            <!-- 允許系統發送 line notify -->
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                :id="`ReceiveLineNotify${data.id}`"
                                v-model="data.receiveLineNotify"
                              />
                              <label
                                class="form-check-label"
                                :for="`ReceiveLineNotify${data.id}`"
                                >允許系統發送 line notify</label
                              >
                            </div>
                            <!-- 允許設定賣場設定 -->
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                :id="`UpdateStore${data.id}`"
                                v-model="data.updateStore"
                              />
                              <label
                                class="form-check-label"
                                :for="`UpdateStore${data.id}`"
                                >允許設定賣場設定</label
                              >
                            </div>
                            <!-- 允許審核顧客 -->
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                :id="`VerifyParticipant${data.id}`"
                                v-model="data.verifyParticipant"
                              />
                              <label
                                class="form-check-label"
                                :for="`VerifyParticipant${data.id}`"
                                >允許審核顧客</label
                              >
                            </div>
                            <!-- 允許使用推播指令 -->
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                :id="`LineGroupPushMerchandise${data.id}`"
                                v-model="data.lineGroupPushMerchandise"
                              />
                              <label
                                class="form-check-label"
                                :for="`LineGroupPushMerchandise${data.id}`"
                                >允許使用推播指令</label
                              >
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <button class="tw-btn tw-btn-success" @click="savePermission(data)">
                          儲存
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- 加入的賣場 -->
          <div>
            <p class="title fw-bold tw-border-start mb-2">加入的賣場</p>
            <table
              class="table table-hover"
              v-if="customerStores.data.length > 0"
            >
              <thead>
                <tr>
                  <th scope="col">賣場名稱</th>
                  <th scope="col">個人賣場狀態</th>
                  <th scope="col">審核狀態</th>
                  <th scope="col">操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="data in customerStores.data" :key="data.storeId">
                  <th scope="row">
                    {{ data.store.name }}
                  </th>
                  <td>
                    <p class="text-success mb-1" v-if="data.store.opened">
                      開放中
                    </p>
                    <p class="text-danger mb-1" v-else>關閉中</p>
                  </td>
                  <td>
                    <p class="text-warning" v-if="data.verify === null">
                      審核中
                    </p>
                    <p class="text-success" v-else-if="data.verify">審核通過</p>
                    <p class="text-danger" v-else>審核未通過</p>
                  </td>
                  <td>
                    <button
                      class="tw-btn tw-btn-danger"
                      @click="showModal('withdrawStore', data)"
                    >
                      退出此賣場
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <p class="fw-bolder text-danger" v-else>目前尚未有任何加入賣場!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 取消管理的 modal (取代刪除賣場) -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="setDeletedTrueModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">
            <!-- 取消管理 -->
            刪除賣場
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="mb-3 alert alert-info">以下為已綁定的社群</p>
          <ul class="mb-3">
            <li class="mb-1">FB 社團: {{ linkSocialData.fbGroup }}</li>
            <li class="mb-1">FB 粉專: {{ linkSocialData.fbGroup }}</li>
            <li class="mb-1">
              Line Bot:
              <span v-if="linkSocialData.lineBot.length === 0">
                沒有綁定任何機器人
              </span>
              <span v-else>
                <span
                  v-for="(data, index) in linkSocialData.lineBot"
                  :key="index"
                >
                  {{ data }}
                </span>
              </span>
            </li>
            <li class="mb-1">
              Line Notify:
              <span v-if="linkSocialData.lineNotify.length === 0">
                沒有綁定任何Notify
              </span>
              <span v-else>
                <span
                  v-for="(data, index) in linkSocialData.lineNotify"
                  :key="index"
                >
                  {{ data }}
                </span>
              </span>
            </li>
          </ul>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="IsUnlinkToSoicalCommunities"
              v-model="linkSocialData.isUnlinkToSoicalCommunities"
            />
            <label
              class="form-check-label fw-bold"
              for="IsUnlinkToSoicalCommunities"
            >
              是否取消社群與此賣場的關聯 ?
            </label>
          </div>
          <p class="mb-3 alert alert-danger fw-bold">
            注意! 刪除後，該賣場的所有顧客、小幫手以及團購主(您)皆無法再「取得該賣場的相關資料」或「進入該賣場」喔!
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-danger"
            @click="setDelete(true)"
          >
            確認刪除
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 繼續管理的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="setDeletedFalseModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">繼續管理</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="alert alert-success">是否繼續管理此賣場 ?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="setDelete(false)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 編輯賣場名稱的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="editStoreNameModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">編輯賣場名稱</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="EditStoreName"
              placeholder="賣場名稱(30字)"
              maxlength="30"
              v-model="storeName"
            />
            <label for="EditStoreName">賣場名稱(30字)</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
            @click="editStoreName('close')"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="editStoreName"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 刪除賣場的 modal (已移除) -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="delStoreModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header alert alert-danger">
          <h5
            class="
              modal-title
              border-start
              ps-2
              border-5 border-danger
              text-danger
              fw-bold
            "
          >
            刪除賣場
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="mb-2">確定要刪除此賣場嗎 ?</p>
          <p class="alert alert-warning fw-bolder">
            注意! 此功能會將您的賣場所有資訊從系統中不留痕跡的刪除喔 !
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="delStore">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 退出賣場的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="withdrawStoreModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">退出賣場</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">確定退出此賣場 ?</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="withdrawStore"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 顯示客服 QRcode 的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="showCustomerServiceModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">Line 客服 QRcode</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body text-center">
          <p class="fw-bolder alert-success p-3">Line 客服 QRcode</p>
          <div class="alert-warning p-3">
            <img
              src="@/assets/line-official/M_gainfriends_qr.png"
              alt="Line客服QRcode"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 檢視小幫手欄位期限的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="checkPermissionsModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">小幫手資訊</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ul class="list" v-if="permission">
            <li>欄位ID: {{ permission.id }}</li>
            <li v-for="data in permission.paymentRecordPermissionLinks" :key="data.id">
              <span
                class="alert-success p-2"
                v-if="data.enableTime <= new Date() * 1 && new Date() * 1 <= data.expirationTime"
              >已生效</span>
              <span class="alert-danger p-2" v-else>未生效</span>
              生效日: {{ $methods.moment(data.enableTime).format('YYYY-MM-DD HH:mm:ss') }}，
              截止日: {{ $methods.moment(data.expirationTime).format('YYYY-MM-DD HH:mm:ss') }}
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// vuex
import { mapState } from "vuex";
// API
import { GetStoreList } from '../../methods/API/getStoreList'
import { parseToken, sortoutParseTokenResult } from '../../methods/API/parseToken'

export default {
  data() {
    return {
      // * modal
      setDeletedTrueModal: {},
      setDeletedFalseModal: {},
      editStoreNameModal: {},
      delStoreModal: {},
      withdrawStoreModal: {},
      showCustomerServiceModal: {},
      checkPermissionsModal: {},
      // * data
      serverToken: '',
      // 新增賣場
      storeName: '',
      selectStoreInfo: {},
      linkSocialData: {
        fbGroup: '',
        fbPage: '',
        lineBot: [],
        lineNotify: [],
        isUnlinkToSoicalCommunities: false
      },
      canNotCreateStore: false,
      show30DaysFreeText: false,
      open30DaysFreeText: false,
      selectAllPermissions: false,
      // 小幫手資訊
      permission: {},
    }
  },
  mixins: [GetStoreList],
  created() {
    this.serverToken = this.$methods.getCookie('serverToken')
    this.getUserInfo()
    this.getUserInfoForStoreList()
  },
  mounted() {
    this.createModals([
      'setDeletedTrueModal', 'setDeletedFalseModal', 'editStoreNameModal',
      'delStoreModal', 'withdrawStoreModal', 'showCustomerServiceModal',
      'checkPermissionsModal'
    ])
  },
  computed: {
    ...mapState('StoreList', {
      userInfo: state => state.userInfo,
    }),
  },
  watch: {
    selectAllPermissions(val) {
      this.userInfo.participantPermissions.forEach(item => {
        if (val) item.selected = true
        else item.selected = false
      })
    },
  },
  methods: {
    // 開啟 modal
    showModal(status, data) {
      if (status === 'setDeletedTrue') {
        // 取消管理
        this.selectStoreInfo = {}
        this.selectStoreInfo = JSON.parse(JSON.stringify(data))
        this.getLinkSocial()
        this.setDeletedTrueModal.show()
      } else if (status === 'setDeletedFalse') {
        // 繼續管理
        this.selectStoreInfo = {}
        this.selectStoreInfo = JSON.parse(JSON.stringify(data))
        this.setDeletedFalseModal.show()
      } else if (status === 'editStoreName') {
        // 編輯賣場名稱
        this.selectStoreInfo = {}
        this.selectStoreInfo = JSON.parse(JSON.stringify(data))
        this.storeName = JSON.parse(JSON.stringify(data.store.name))
        this.editStoreNameModal.show()
      } else if (status === 'delStore') {
        // 刪除賣場
        this.selectStoreInfo = {}
        this.selectStoreInfo = JSON.parse(JSON.stringify(data))
        this.delStoreModal.show()
      } else if (status === 'withdrawStore') {
        // 退出賣場
        this.selectStoreInfo = {}
        this.selectStoreInfo = JSON.parse(JSON.stringify(data))
        this.withdrawStoreModal.show()
      } else if (status === 'showCustomerService') {
        // 顯示客服 qrcode
        this.showCustomerServiceModal.show()
      } else if (status === 'checkPermissions') {
        // 檢視小幫手期限
        this.permission = {}
        this.permission = data
        this.checkPermissionsModal.show()
      }
    },
    // 取得使用者 ID
    getUserInfo() {
      this.$methods.switchLoading('show')
      this.userInfo = {}
      const result = parseToken(this.serverToken)
      result.then(res => {
        const result = sortoutParseTokenResult(res)
        this.getPhysicalUserInfo(result)
      }).catch(err => {
        console.log(err.responseJSON)
        this.SweetAlert('other', err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // 取得實體使用者資訊
    getPhysicalUserInfo(userInfo) {
      const vm = this
      const getPhysicalUserInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 1,
          ids: userInfo.ids,
          methods: '{getCurrentSubscriptionProject,getPaymentRecords{getPaymentVerifyRecords}}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getPhysicalUserInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const userInfo = res.data[0].objects[0]
            vm.checkCanCreateStore(userInfo)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 檢查是否可以建立賣場
    checkCanCreateStore(userInfo) {
      if (!userInfo.currentSubscriptionProject) {
        const tried = userInfo.paymentRecords.some(item => {
          return !item.deleted && item.paymentVerifyRecords.length > 0 && item.planType === 'FREE_TRIAL'
        })
        console.log(`tried: ${tried}`)
        if (!tried) {
          // 還沒試用
          this.show30DaysFreeText = true
          this.canNotCreateStore = false
        } else {
          // 已試用
          this.canNotCreateStore = true
        }
      }
      
    },
    // 建立賣場
    createStore() {
      if (!this.storeName) return this.SweetAlert('other', '賣場名稱不得為空')
      this.$methods.switchLoading('show')
      const vm = this
      const createStoreApi = `${process.env.VUE_APP_API}/store/create`
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData()
      data.append('names', this.storeName)
      $.ajax({
        type: 'POST',
        async: true,
        url: createStoreApi,
        data: data,
        headers: header,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getUserInfoForStoreList('user')
            vm.storeName = ''
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 編輯賣場
    editStoreName(status) {
      if (status === 'close') return this.storeName = ''
      if (!this.storeName) return this.SweetAlert('other', '賣場名稱不得為空')
      this.$methods.switchLoading('show')
      const vm = this
      const editStoreNameApi = `${process.env.VUE_APP_API}/store/setAttribute`
      const header = {
        authorization: this.serverToken
      }
      let data = [
        {
          id: this.selectStoreInfo.storeId,
          attributes: {
            setName: this.storeName
          }
        }
      ]
      $.ajax({
        type: 'PUT',
        async: true,
        url: editStoreNameApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getUserInfoForStoreList('user')
            vm.storeName = ''
            vm.editStoreNameModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 刪除賣場 (在前端先不用，因為刪除賣場後，帳單的併單若有跨賣場，最上階的帳單也會被刪除，導致下階賣場有 parentId 但該父層帳單已被刪除)
    delStore() {
      if (!this.selectStoreInfo.store.deleted) return this.SweetAlert('other', '請先取消管理賣場')
      this.$methods.switchLoading('show')
      const vm = this
      const delStoreApi = `${process.env.VUE_APP_API}/store/delete`
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData()
      data.append('ids', this.selectStoreInfo.storeId)
      $.ajax({
        type: 'DELETE',
        async: true,
        url: delStoreApi,
        data: data,
        headers: header,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getUserInfoForStoreList('user')
            vm.delStoreModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 取得賣場相關社群連結
    getLinkSocial() {
      this.$methods.switchLoading('show')
      const vm = this
      const getLinkSocialApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.selectStoreInfo.storeId],
          methods: '{getStoreFbGroupLinks,getStoreFbPageLinks,getStoreLineNotifyLinks,getStoreLineGroupLinks{getSummary}}'
        }
      ]
      this.linkSocialData = {
        fbGroup: '',
        fbPage: '',
        lineBot: [],
        lineNotify: [],
        isUnlinkToSoicalCommunities: false
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getLinkSocialApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            // 檢查是否有 FB 社團關聯
            if (storeInfo.storeFbGroupLinks.length === 0) {
              vm.linkSocialData.fbGroup = '沒有綁定任何社團'
            } else {
              vm.linkSocialData.fbGroup = '沒有綁定任何社團'
              storeInfo.storeFbGroupLinks.forEach(item => {
                if (!item.deleted) vm.linkSocialData.fbGroup = item.name
              });
            }
            // 檢查是否有 FB 社團關聯
            if (storeInfo.storeFbPageLinks.length === 0) {
              vm.linkSocialData.fbPage = '沒有綁定任何粉專'
            } else {
              vm.linkSocialData.fbPage = '沒有綁定任何粉專'
              storeInfo.storeFbPageLinks.forEach(item => {
                if (!item.deleted) vm.linkSocialData.fbPage = item.name
              });
            }
            // 檢查是否有綁定 Line Bot
            if (storeInfo.storeLineGroupLinks.length > 0) {
              storeInfo.storeLineGroupLinks.forEach(item => {
                if (!item.deleted) vm.linkSocialData.lineBot.push(item.summary.groupName)
              })
            }
            // 檢查是否有綁定 Line Notify
            if (storeInfo.storeLineNotifyLinks.length > 0) {
              storeInfo.storeLineNotifyLinks.forEach(item => {
                vm.linkSocialData.lineNotify.push(item.groupName)
              })
            }
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 更新賣場屬性
    setStoreAttribute(attribute, item) {
      this.$methods.switchLoading('show')
      const vm = this
      const setStoreAttributeApi = `${process.env.VUE_APP_API}/store/setAttribute`
      const header = {
        authorization: this.serverToken
      }
      let data = []
      console.log(item)
      switch (attribute) {
        case "setOpened":
          data.push(
            {
              id: item.storeId,
              attributes: {
                setOpened: !item.store.opened
              }
            }
          )
          break;
        case "setCanCheckOut":
          data.push(
            {
              id: item.storeId,
              attributes: {
                setCanCheckOut: !item.store.canCheckOut
              }
            }
          )
          break;
        case "setReceivingInfoNeeded":
          data.push(
            {
              id: item.storeId,
              attributes: {
                setReceivingInfoNeeded: !item.store.receivingInfoNeeded
              }
            }
          )
          break;
        case "setVerifyNeeded":
          data.push(
            {
              id: item.storeId,
              attributes: {
                setVerifyNeeded: !item.store.verifyNeeded
              }
            }
          )
          break;
      }
      $.ajax({
        type: 'PUT',
        async: true,
        url: setStoreAttributeApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getUserInfoForStoreList('user')
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 取消/繼續管理賣場 (先用來取代刪除賣場)
    setDelete(status) {
      this.$methods.switchLoading('show')
      const vm = this
      const setDeleteApi = `${process.env.VUE_APP_API}/store/setDeleted`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          id: this.selectStoreInfo.storeId,
          deleted: status,
          isUnlinkToSoicalCommunities: this.linkSocialData.isUnlinkToSoicalCommunities
        }
      ]
      $.ajax({
        type: 'PUT',
        async: true,
        url: setDeleteApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.setDeletedTrueModal.hide()
            vm.setDeletedFalseModal.hide()
            vm.getUserInfoForStoreList('user')
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 退出賣場
    withdrawStore() {
      this.$methods.switchLoading('show')
      const vm = this
      const withdrawStoreApi = `${process.env.VUE_APP_API}/participant/withdrawStore`
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData()
      data.append('storeId', this.selectStoreInfo.storeId)
      $.ajax({
        type: 'PUT',
        async: true,
        url: withdrawStoreApi,
        data: data,
        headers: header,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getUserInfoForStoreList('user')
            vm.withdrawStoreModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 儲存小幫手
    savePermission(participantPermission) {
      // 檢查 ID 是否重複
      if (participantPermission.participantId) {
        let repeat = 0
        this.userInfo.participantPermissions.forEach(item => {
          console.log(item.participantId, participantPermission.participantId)
          if (item.participantId == participantPermission.participantId) repeat ++
        })
        if (repeat > 1) return this.SweetAlert('other', 'ID重複')
      }
      // 開始執行
      this.$methods.switchLoading('show')
      const vm = this
      const saveApi = `${process.env.VUE_APP_API}/participantPermission/update`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        id: participantPermission.id,
        participantId: participantPermission.participantId,
        deleteMerchandise: participantPermission.deleteMerchandise,
        deleteMerchOrder: participantPermission.deleteMerchOrder,
        deleteParticipant: participantPermission.deleteParticipant,
        verifyParticipant: participantPermission.verifyParticipant,
        updateStore: participantPermission.updateStore,
        accessSupplier: participantPermission.accessSupplier,
        accessStoreReport: participantPermission.accessStoreReport,
        accessCost: participantPermission.accessCost,
        receiveLineNotify: participantPermission.receiveLineNotify,
        lineGroupPushMerchandise: participantPermission.lineGroupPushMerchandise
      }]
      $.ajax({
        type: 'PUT',
        async: true,
        url: saveApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code)
            vm.getUserInfoForStoreList('user')
            vm.$methods.switchLoading('hide')
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
  }
}
</script>